<template>
  <section>
    <navbar-register></navbar-register>
    <b-row class="justify-content-md-center pt-10 px-1">
      <b-col md="6">
        <b-card class="text h-auto">
          <div class="text-center">
            <h3>{{ $t('complete_info') }}</h3>
          </div>
          <hr>
          <br>
          <form-validation-locale @next="goTo" v-if="rol !== 'creator'" />
          <form-creator @next="goTo" v-if="rol === 'creator'" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {BRow, BCol, BCard} from 'bootstrap-vue'
import FormValidationLocale from '@/views/forms/AccountCompanyForm'
import FormCreator from '@/views/forms/CreatorForm'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    FormValidationLocale,
    FormCreator,
    NavbarRegister: () => import('@/views/components/navbar/NavbarRegister.vue')
  },
  data () {
    return {
      rol: '',
      user: JSON.parse(localStorage.getItem('userData'))
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  created () {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.rol = this.getRol()
  },
  methods: {
    getRol () {
      const user = JSON.parse(localStorage.userData)
      return user.groups[0].name
    },
    goTo () {
      const accept_tyc = localStorage.getItem('accept_tyc');
      const route_realty = this.rol === 'realty' ? '/campaigns' : '/dashboard'
      const route = accept_tyc === 'true' ? route_realty : '/email-confirmation'
      window.location.href = route
    }
  }
}
</script>
<style>
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
.form-file-avatar {
  z-index: 0;
}
</style>
<style lang="scss" scoped>
.pt-10 {
  padding-top: 10em;
}
</style>